export default [
    { title: '跳跳乐x1-丛林', num: 7 },
    { title: '跳跳乐x2-机关', num: 12 },
    { title: '跳跳乐x3-视野限制的沼泽', num: 17 },
    { title: '跳跳乐x4-若隐若现的云朵', num: 22 },
    { title: '跳跳乐x5-透明台阶', num: 23 },
    { title: '听音识曲', num: 24 },
    { title: '跳跳乐x6-选岔路', num: 27 },
    { title: '老者问答', num: 39 },
    { title: '跳跳乐x7', num: 41 },
    { title: '移速限制-清理小怪', num: 42 },
    { title: '跳跳乐x8-颠倒黑白', num: 43 },
    { title: '跳跳乐x9-错综复杂', num: 44 },
    { title: '跳跳乐Max-透明台阶', num: 48 },
    { title: '剪影猜怪物', num: 49 },
]